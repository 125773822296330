.card_w{
    border-radius: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    height: 400px;
    padding: 60px 70px;
    background-position: center;
    background-size: cover;
    @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    height: fit-content;
    padding: 30px 20px;

    }
    .left_w{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title{
            font-weight: 700;
            font-size: 48px;
            color: white;
            line-height: 50px;
            @media screen and (max-width: 600px) {
                font-size: 32px;
            line-height: 30px;

            }
        }
        .sub_title{
            font-size: 20px;
            font-weight: 700;
            color: #FFFFFF;
            @media screen and (max-width: 600px) {
                margin-top: 15px;
            }
        }
        button{
            border-radius: 25px;
            background-color: black;
            padding: 10px 30px;
            color: white;
            width: fit-content;
            @media screen and (max-width: 600px) {
                display: none;
            }
        }
    }
    .right_w{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        img{
            width: 90%;
            @media screen and (max-width: 600px) {
                width: 200px;

                }
        }
        button{
            border-radius: 25px;
            background-color: black;
            padding: 10px 30px;
            color: white;
            width: 100%;
            display: none;
            @media screen and (max-width: 600px) {
            display: block;
                
            }
        }
    }
}