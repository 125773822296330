.soc_wrapper {
  background-color: #f35503;
  position: relative;
  z-index: 3;
  overflow: hidden;
  .soc_top__w {
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    .w_thr {
      font-weight: 700;
      font-size: 64px;
      color: white;
    }
    img {
      width: 100%;
    }

    .marquee {
      overflow: hidden;
      position: relative;
      text-align: start;
    }

    .marqueeContent {
      display: flex;
      white-space: nowrap;
    }

    .marqueeText {
      font-weight: 900;
      font-size: 64px;
      color: #242424;
      padding-right: 100px; /* Space between the two text items */
    }
  }

  .soc_bot__w {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    grid-template-rows: 1fr;
    padding: 100px 0;
    @media screen and (max-width: 880px) {
    grid-template-columns: 1fr;
      
    }
    > div {
      &:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;

        p {
          font-size: 24px;
          font-weight: 500;
          color: #242424;
        }

        .w_thr {
          font-weight: 700;
          font-size: 64px;
          color: white;
        }

        .imgg {
          width: 100%;
          border: 3px solid black;

          img {
            width: 100%;
          }
        }
      }

      &:last-child {
        > div {
          padding: 20px;
          border: 0;
          border-top: 1px solid white;
          border-bottom: 1px solid white;
          position: relative;

          &:not(:last-child) {
            &::after {
              content: "";
              position: absolute;
              background-color: white;
              left: -21px;
              top: 30px;
              width: 2px;
              height: calc(
                100% + 10px
              ); // Уменьшите на высоту блока (в этом примере 30px сверху и снизу)
              @media screen and (max-width: 600px) {
              left: 9px;
                
              }
            }
          }

          p {
            font-size: 24px;
            color: white;
            font-weight: 500;
            position: relative;
            padding-left: 40px;
@media screen and (max-width: 600px) {
  padding-left: 10px;
  
}
            &::before {
              content: url("../../../public/images/circleW.svg");
              position: absolute;
              left: -50px;
              top: 0;
              width: 30px;
              height: 30px;
              @media screen and (max-width: 600px) {
              left: -20px;
                
              }
            }
          }
        }
      }
    }
  }
}
.blocks{
    position: relative;
    height: 100px;
    background-color: white;
}
.clip {
    width: 100%;
    clip-path: ellipse(51% 65% at 50% 0%);
    height: 100px;
    background-color: #f35503;
    position: absolute; /* Позиционирование для наложения на границу контейнера */
    top: 0; /* Убедитесь, что элемент располагается внутри контейнера */
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    img{
      position: relative;
        width: 300px;
        bottom: 18px;
    }
  }
  .clip2 {
    width: 100%;
    clip-path: ellipse(54% 85% at 50% 0%);
    height: 100px;
    background-color: #13171A;
    position: absolute; /* Позиционирование для наложения на границу контейнера */
    top: 0; /* Убедитесь, что элемент располагается внутри контейнера */
    left: 0;
    z-index: 2;
  }
.cr{
  color: #13171A;
  font-size: 35px;
  font-weight: 700;
  width: 100%;
}
 