.s_back{
    background-image: url("../../../public/images/backsli.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
}
.slider_ww{
    padding: 80px 200px 80px 200px;
    @media screen and (max-width: 1400px) {
    padding: 80px 100px 80px 100px;
        
    }
    @media screen and (max-width: 600px) {
        padding: 20px;
        
    }
    @media screen and (max-width: 350px) {
        padding: 0px;
        
    }
    :global{
        .slick-slide{
            >div{
                padding: 0 200px;
                @media screen and (max-width: 600px) {
                    padding: 20px;
                    
                }
            }
            
        }
        .slick-dots li.slick-active button:before{
            color: #FB610A ;
            opacity: 1;
        }
        .slick-dots li button:before{
            font-size: 15px;
        }
        .slick-arrow{
            display: flex !important;
            justify-content: center;
            align-items: center;
        }
        .slick-arrow::before{
            content: "";
        }
      }
}
.blocks{
    position: relative;
    height: 100px;
    display: flex;
    justify-content: center;
    img{
        width: 299px;
        position: relative;
        top: 
        0px;
z-index: 3;
transform: rotate(-180deg);
@media screen and (max-width: 400px) {
    width: 185px;
    top: -12px;
}
    }
}
.clip {
    width: 100%;
    clip-path: ellipse(51% 90% at 50% 109%);
    height: 100px;
    background-color: #f35503;
    position: absolute; /* Позиционирование для наложения на границу контейнера */
    top: 0; /* Убедитесь, что элемент располагается внутри контейнера */
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    
  }
  .clip2 {
    width: 100%;
    clip-path: ellipse(53% 100% at 50% 102%);
    height: 100px;
    background-color: #13171A;
    position: absolute; /* Позиционирование для наложения на границу контейнера */
    top: 0; /* Убедитесь, что элемент располагается внутри контейнера */
    left: 0;
  }
  .customNextArrow {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 1;
    cursor: pointer;
  }
  
  .customPrevArrow {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 1;
    cursor: pointer;
  }
  