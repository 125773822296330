.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > img {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  > div {
    width: 80%;
    @media screen and (max-width: 600px) {
      width: 95%;
    }
  }
}

.title {
  color: white;
  font-size: 48px;
  font-weight: 700;
  padding: 30px 0;
  @media screen and (max-width: 600px) {
    font-size: 34px;
    text-align: center;
  }
}
@media (max-width: 600px) {
  .celi {
    text-align: start;
  }
}
.o_desc {
  color: #fb610b;
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 600px) {
    font-size: 16px;
  }
}

.p_title {
  color: white;
  font-size: 20px;
}

.present_block {
  > div {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column-reverse;
    }
    > div {
      padding: 50px 0 30px 0;

      &:first-child {
        width: 35%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 600px) {
          width: 100%;
        }
        button {
          background-color: #f35503;
          border-radius: 200px;
          padding: 18px 48px;
          font-weight: 700;
          font-size: 14px;
          color: white;
        }
      }
      &:last-child {
        width: 65%;
        @media (max-width: 600px) {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 25px;
        }
        p {
          font-size: 16px;
          color: #ffffff;
          span {
            color: #f35503;
            @media (max-width: 600px) {
              display: block;
            }
          }
        }
      }
    }
  }
}
.rules {
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
    .r_title {
      color: #fb610a;
      font-weight: 600;
    }
    .desc {
      color: #d9d9d9;
      font-weight: 600;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #fb610a;
        border-radius: 200px;
        left: -15px;
        top: 5px;
      }
    }
  }
}
.info_block {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 30px 0;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
  .chel {
    display: block;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .phim {
    display: none;
    @media (max-width: 600px) {
      display: block;
    }
  }
  .c_title {
    font-size: 28px;
    color: #fb610a;
    font-weight: 500;
  }
  .c_desc {
    font-size: 16px;
    color: #d9d9d9;
    margin-top: 20px;
  }
}

.r_block {
  width: 70%;
  @media (max-width: 600px) {
    width: 100%;
  }
  > div {
    padding: 20px;
    border: 0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    position: relative;

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        background-color: white;
        left: -21px;
        top: 30px;
        width: 2px;
        height: calc(
          100% + 10px
        ); // Уменьшите на высоту блока (в этом примере 30px сверху и снизу)
        @media screen and (max-width: 600px) {
          left: 9px;
        }
      }
    }

    p {
      font-size: 20px;
      color: white;
      font-weight: 500;
      position: relative;
      padding-left: 40px;
      @media screen and (max-width: 600px) {
        padding-left: 10px;
        font-size: 14px;
      }
      &::before {
        content: url("../../../public/images/circleW.svg");
        position: absolute;
        left: -50px;
        top: 0;
        width: 30px;
        height: 30px;
        @media screen and (max-width: 600px) {
          left: -20px;
        }
      }
    }
  }
}
