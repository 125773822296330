.form_w {
  background-color: #242424;
  overflow: hidden;

  >form {
    padding: 100px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    position: relative;
    z-index: 10;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      height: 160vh;
      bottom: 400px;
    }

    >img {
      width: 100%;
    }

    >video {
      width: 100%;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 50px;

      .title {
        font-weight: 700;
        font-size: 48px;
        color: #ffffff;
        margin-bottom: 30px;
        @media (max-width: 400px) {
          font-size: 36px;
      
      }}

      input {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid white;
        padding: 15px 0;
        color: white;

        &::placeholder {
          color: white;
        }
      }

      input[type="submit"] {
        background-color: black;
        color: white;
        border: 1px solid black;
        padding: 15px 25px;
        border-radius: 24px;
        width: fit-content;
        transition: ease-in-out .3s;
        &:hover{
          background-color: #FB610B;
          cursor: pointer;
        border: 1px solid #FB610B;
          
  -webkit-box-shadow: 0px 0px 15px 4px rgba(255,161,54,0.5);
  -moz-box-shadow: 0px 0px 15px 4px rgba(255,161,54,0.5);
  box-shadow: 0px 0px 15px 4px rgba(255,161,54,0.5);
        }
        @media screen and (max-width: 600px) {
          width: 100%;
          display: none;
        }
      }
    }

    .img_upload {
      display: flex;
      align-items: flex-end;

      >div {
        width: 100%;
      }
    }
  }

  .hors {
    position: absolute;
    right: -70px;
    top: -430px;
    width: 38%;
  }

  .lineb {
    img {
      width: 100%;
    }
  }

  .logos_slider__wrap {
    background-color: #313131;
  }
}
:global{
  .slick-slide {
    > div{
      width: 100%;
      >div{
        >div{
          width: 100%;
          img{
            width: 100%;
          }
        }
      }
    }
  }
}
.nonImg_w {
  overflow: hidden;
  height: 550px;
  position: relative;
  display: none;
  width: 100%;

  @media screen and (max-width: 600px) {
    display: block;
    overflow: hidden;
  height: 1000px;
  position: relative;
  width: 100%;
  }

  @media screen and (max-width: 425px) {
    display: block;
    overflow: hidden;
  height: 350px;
  margin-top: 60px;
  position: relative;
  width: 100%;
  }
  @media screen and (max-width: 350px) {
    display: block;
    overflow: hidden;
  height: 300px;
  position: relative;
  width: 100%;
    }
}

.nonImg {
  position: absolute;
  top: 650px;
  transform: translate(10%);
width: 100%;
left: -40px;


@media screen and (max-width: 425px) {
  top: 400px;
}

@media screen and (max-width: 375px) {
  top: 300px;
}
@media screen and (max-width: 375px) {
  top: 40px;
}

}

@media screen and (max-width: 600px) {
  :global {
    .slick-slide img {
      width: 100%;
    }
  }
}

.reg__phone__cont{
  width: 100%;
  display: flex;
  justify-content: center;
}

.reg__phone{
 display: none;

  @media screen and (max-width: 600px) {
    display: block;
    width: 100%;
    background-color: black;
    color: white;
    border: 1px solid black;
    padding: 15px 25px;
    border-radius: 24px;
    width: fit-content;
  }
}