.container{
  width: 85%;
  margin: 0 auto;
}
a{
  text-decoration: none;
  color: white;
}
button{
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: ease-in-out .3s;
}

.uploader {
  border: #333333 solid;
  box-sizing: content-box;
  width: 300px;
  height: 300px;
  cursor: pointer;
  display: flex;
  margin: auto;
  border-radius: 20px;
}
@media screen and (max-width: 600px) {
  .uploader {
    width: 100%;
    height: 300px;
  }
}

.uploader p {
  margin: auto;
  color: #CACCCD;
  font-size: 17px;
  font-weight: 600;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #333333;
  border-radius: 16px;
}

.uploaded-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.other-image-thumbnails {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.img-thumb {
  width: 140px;
  height: 120px;
  border: 1px solid purple;
  object-fit: cover;
  flex: 1;
}

.slick-slider{
  .slick-prev, .slick-next {
      display: none !important;
  }
  
  .slick-dots {
      display: none !important;
  }
}
button:hover{
  cursor: pointer !important;
  background-color: #F35503 !important;
  
  -webkit-box-shadow: 0px 0px 15px 4px rgba(255,161,54,0.5);
-moz-box-shadow: 0px 0px 15px 4px rgba(255,161,54,0.5);
box-shadow: 0px 0px 15px 4px rgba(255,161,54,0.5);
}