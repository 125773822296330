@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap");
.footer_w {
  background-color: #313131;
  .top_foot {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    overflow: hidden;
    gap: 20px;
    @media screen and (max-width: 600px) {
    padding: 30px 0;

      }
    .title {
      font-size: 42px;
      font-weight: 700;
      color: white;
      font-family: "Comfortaa", sans-serif;
    }
    a {
      font-family: "Comfortaa", sans-serif;
      &:first-child {
        font-size: 24px;
        font-weight: 400;
      }
      img{
        
      }
    }
    > img {
      width: 100%;
      @media screen and (max-width: 600px) {
        width: 200%;
      }
    }
  }
  .bot_foot {
    background-color: #171717;
    padding: 40px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 50px;
        padding: 50px 0;
      }
    > img {
      width: 140px;
    }
    > p {
      font-size: 14px;
      color: white;
    }
    @media screen and (max-width: 600px) {
        a{
            display: none;
        }
      }
  }
}
