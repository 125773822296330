.programms_wrapper {
    background-color: #242424;
    position: relative;
    >img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
      }
    >div {
        position: relative;
        overflow: hidden;

        .title_b {
            font-size: 48px;
            font-weight: 700;
            color: white;
            margin-bottom: 25px;
            margin-top: 80px;
        }

        .years_b {
            display: flex;
            align-items: center;
            gap: 10px;

            >button {
                border: 1px solid white;
                padding: 10px 20px;
                color: white;
                border-radius: 25px;
                font-size: 16px;
                font-weight: 500;
            }

            .activeBtn {
                background-color: #F35503;
                border: 1px solid #F35503;
            }
        }

        .programms_card_w {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 60px;
            margin-bottom: 80px;
            gap: 27px;
            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr 1fr;
                margin-bottom: 350px;

            }

            @media screen and (max-width: 600px) {
                grid-template-columns: 1fr;

            }

            .program_card {
                perspective: 1000px;

                .card_inner {
                    position: relative;
                    width: 100%;
                    height: 300px;
                    transform-style: preserve-3d;
                    transition: transform 0.6s;
                }

                &:hover .card_inner {
                    transform: rotateY(180deg);
                }

                .card_front,
                .card_back {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    backface-visibility: hidden;
                    border-radius: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #13171A;
                    border: 0.5px solid white;

                    p {
                        color: #FFFFFF;
                        font-weight: 700;
                        font-size: 40px;
                        @media screen and (max-width: 400px) {
                        font-size: 30px;
                            text-align: center;
                        }
                    }
                }

                .card_back {
                    transform: rotateY(180deg);
                    flex-direction: column;
                    align-items: flex-start;
                    position: relative;
                    padding: 30px;
                    gap: 20px;
                    color: white;
                    background-color: #F35503;

                    .top_anim {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 45px;

                        img {
                            border-radius: 24px 24px 0 0;
                            width: 100%;
                        }
                    }

                    button {
                        padding: 10px 20px;
                        color: white;
                        border-radius: 25px;
                        font-size: 16px;
                        font-weight: 500;
                        background-color: black;
                        position: absolute;
                        right: 20px;
                        bottom: 30px;
                    }
                    .el_date{
                        font-size: 36px; /* Измените размер шрифта или другие стили для обратной стороны */
                        @media screen and (max-width: 400px) {
                            font-size: 25px;
    
                            }

                        }
                    p {
                        font-size: 20px; /* Измените размер шрифта или другие стили для обратной стороны */
                        @media screen and (max-width: 400px) {
                            font-size: 16px;
                            text-align: start;
                            }
                    }
                }
            }
        }

        .hors {
            position: absolute;
            right: -70px;
            bottom: -450px;
            width: 38%;

            @media screen and (max-width: 600px) {
                width: 100%;
                bottom: -250px;
                right: 0;
            }
        }

        .carlab {
            position: absolute;
            top: 80px;
            right: 0;
            width: 70%;
            opacity: 0.2;

            @media screen and (max-width: 600px) {
                grid-template-columns: 1fr;
                top: 30px;
                width: 100%;
            }
        }
    }
}
.desc2{
    font-weight: 300 !important;
}