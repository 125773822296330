h2 {
  font-weight: 700;
  font-size: 48px;
  color: white;
  padding: 2% 5% 0 5%;
}
.dd {
  text-align: center;
  color: #cacccd;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}
.container {
  display: flex;
  width: 100%;
  padding: 4% 2%;
  box-sizing: border-box;
  height: 60vh;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 80vh;
    gap: 20px;
  }
}

.box {
  flex: 1;
  overflow: hidden;
  transition: 0.5s;
  background-color: #333333;
  margin: 0 10px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  position: relative;
  > div {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: ease-in-out 0.3s;
    .title {
      font-size: 32px;
      font-weight: 700;
      color: #ffffff;
      position: relative;
      left: 30px;
      top: 30px;
      @media screen and (max-width: 768px) {
      font-size: 24px;

      }
    }
    .desc {
      font-weight: 700;
      font-size: 16px;
      color: #fb610a;
      position: relative;

      left: 30px;
      top: 60px;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  &:hover {
    > div {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.601);
    }
  }
}

.box > img {
  width: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.box > span {
  font-size: 3.8vh;
  display: block;
  text-align: center;
  height: 10vh;
  line-height: 2.6;
}

.box:hover {
  flex: 1 1 20%;
}
.box:hover > img {
  width: 100%;
  height: 100%;
}
