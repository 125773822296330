.main_w {
  height: 100vh;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  >img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  mix-blend-mode: multiply;
    width: 100%;
    height: 100%;
   
  }

  @media (max-width: 768px) {
  height: 90vh;
    
  }
  .cont {
    height: 100%;
    position: relative;
    .horseBlock {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-end;
      height: 100%;
      position: relative;

      @media (max-width: 700px) {
        display: none;
      }

      > img {
        width: 49%;

        &:first-child {
          position: absolute;
          left: 0;
        }

        &:nth-child(2) {
          position: absolute;
          right: 0;
        }

        @media (max-width: 700px) {
          display: none;
        }
      }
    }

    .main_content {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 60px 0 0 0;
      z-index: 2;

      > img {
    position: relative;
        top: 20px;
        @media (max-width: 700px) {
          width: 90%;
        }
      }

      p {
        width: 17%;
        text-align: center;
        color: white;
        font-size: 22px;
        position: relative;
        top: 60px;
        @media (max-width: 700px) {
          width: 90%;
          font-size: 16px;
          margin-top: 15px;
        }
      }

      .slider_block {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;

        .blockss {
          display: flex;
          justify-content: center;
          align-items: flex-end;

          > div {
            width: 48%;
            position: absolute;
            bottom: -100%;
            @media screen and (max-width: 600px) {
              width: 70%;
              transform: translate(-17%);
            bottom: -58%;
            left: 38px;

            }
            @media screen and (max-width: 400px) {
              width: 85%;
              transform: translate(-30%);
            bottom: -58%;
            left: 30px;

            }
            img {
              width: 100%;
              height: 100%;
              animation: rotate 5s linear infinite;

              @media screen and (max-width: 600px) {
                width: 154%;
                transform: translate(-17%);
              }
            }
          }
        }
      }
    }
  }
}

.mobileHorse {
  display: none;
  position: absolute;
  height: 100vh;
  top: 0;
  z-index: 1;

  @media (max-width: 700px) {
    display: block;
    width: 100%;

    img {
      width: 100%;
      position: absolute;
      bottom: -7%;
    }
  }
}

.ref_block{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
  >button{
    border-radius: 200px;
    background-color: #F35503;
    border: 1px solid #F35503;
    color: white;
    padding: 15px 25px;
    position: relative;
    top: 100px;
    @media screen and (max-width: 400px) {
      top: 50px;
    }
  }
  >img{
    position: relative;
    top: 100px;
    @media screen and (max-width: 400px) {
      top: 60px;
    }
  }
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.gra{
  background: linear-gradient(0deg, #13171A -11.03%, rgba(74, 74, 74, 0) 100%);
  height: 20%;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 10 ;
}