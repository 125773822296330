.wrapper {
    background: #F35503;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 100px 0 0 0 ;
}

.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.videoFrame {
    position: relative;
    width: 100%;
    height: 450px;
    border: 10px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}

.reactPlayer {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;

    video{
        object-fit: cover;
    }
}

.reactPlayer iframe,
.reactPlayer video {
    border-radius: 15px !important;
    /* Задайте нужный радиус к iframe или видео элементам */
}

.playerButton {
    cursor: pointer;
    z-index: 999;
    position: absolute;
    bottom: -35px;
    left: 44.5%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 0;
    border-top: 8px solid #000000;
    >div{
        display: flex;
        justify-content: center;
        align-items: center;
    border: 8px solid #F35503;
    background-color: #000;
    border-radius: 100%;
    width: 90%;
    left: 44.5%;
    height: 100%;

    }
    @media (max-width:768px) {
        left: auto;
    }

    img {
        width: 22px;
        height: 22px;
    }
}
.c_w{
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 44.0%;
    overflow: hidden;
}
.circle_b{
    width: 80px;
    height: 80px;
    background-color: #000000;
    border-radius: 100%;
    
    position: absolute;
    bottom: -43px;
}
.sideText {
    width: 5%;
    position: absolute;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    left: -70px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: bold;
    color: #000;

    @media (max-width: 768px) {
        display: none;
    }
}

.sideTextRight {
    position: absolute;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    right: -70px;
    bottom: 50%;
    transform: translateY(-50%);
    rotate: 180deg;
    width: 5%;
    color: #000;

    @media (max-width: 768px) {
        display: none;
    }
}

.textOverlayTop {
    width: 70%;
    overflow: hidden;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    margin-bottom: 10px;


    @media (max-width: 768px) {
        width: 150%;
    }
    >div{
        width: 100%;
    }
    img {
        width: 100%;
height: 100%;
      

      
    }
}

.textOverlay {
    width: 70%;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    margin-top: 10px;
    overflow: hidden;

 

    @media (max-width: 768px) {
        width: 150%;
    }
    >div{
        width: 100%;
    }
    img {
        width: 100%;

       
    }
}

.logos_slider__wrap {
    width: 100%;
    margin-top: 70px;
    background-color: #333333;

    .slick-prev,
    .slick-next {
        display: none !important;
    }

    .slick-dots {
        display: none !important;
    }

    .slick-slider {

        .slick-prev,
        .slick-next {
            display: none !important;
        }

        .slick-dots {
            display: none !important;
        }
    }
}