


.programms_wrapper {
    background-color: #FB610B;
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;

    @media (max-width: 768px) {
        padding-bottom: 50px;
    }

    >div {
        position: relative;
        overflow: hidden;
        padding: 0 0 280px 0;
        @media (max-width: 768px) {
        padding: 0 0 60px 0;

        }
        .title_b {
            font-size: 48px;
            font-weight: 700;
            color: white;
            margin-bottom: 25px;
            margin-top: 80px;
        }

        .years_b {
            display: flex;
            align-items: center;
            gap: 10px;

            >button {
                z-index: 999;
                cursor: pointer;
                border: 1px solid white;
                padding: 10px 20px;
                color: white;
                border-radius: 25px;
                font-size: 16px;
                font-weight: 500;
            }

            .activeBtn {
                background-color: #13171A;
                border: 1px solid #13171A;
            }
        }

        .programms_card_w {
            margin-top: 50px;
            width: 100%;
            z-index: 100000000000;

            .ppd {
                width: 100%;

                .logos_w {
                    img {
                        width: 95%;
                    }
                }
            }
        }

        .hors {
            position: absolute;
            right: -70px;
            bottom: -500px;
            width: 38%;
        }

        .carlab {
            position: absolute;
            top: 80px;
            right: 0;
            width: 70%;
            opacity: 0.2;

            @media (max-width: 600px) {
                top: 30px;

                width: 100%;

            }
        }
    }
}

.people {
    position: absolute;
    width: 100% !important;
    bottom: 0px;
    z-index: 15;
}

.blackBlock {
    position: absolute;
    box-shadow: 0px 0px 92.3px 0px #00000066 inset;
    background: #242424;
    right: -40px;
    bottom: 0;
    top: 20px;
    height: 100%;
    width: 30%;
    z-index: -1;

    @media (max-width: 600px) {
        display: none;
    }
}

.ticket {
    margin-top: 35px;
    margin-left: 100px;
    margin-bottom: 80px;
    width: 15%;
    height: 50px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;

    @media (max-width: 600px) {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 0px;

    }
}

.slider_w {
    overflow: hidden;
}

.text__block {
    display: flex;
    margin-top: 21px;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    z-index: 112000;

    p {
        color: rgba(19, 23, 26, 1);
        font-size: 20px;
        font-weight: 700;
    }

    button {
        cursor: pointer;
        width: 154px;
        height: 42px;
        padding: 10px;
        border-radius: 30px;
        color: white;
        background-color: rgba(19, 23, 26, 1);
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        button {
            width: 50%;
            margin-top: 20px;
        }
    }
}