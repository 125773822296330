.modal-overlay {
  position: fixed;
  z-index: 40000000000000000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  /* Полупрозрачный фон */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border: 2px solid #FFFFFF;
  background: #13171A;
  border-radius: 16px;
  position: relative;
  width: 65%;
  max-width: 80%;
  max-height: 80vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 1;
}



.modal-content>div {
  border: 2px solid #FFFFFF;
  background: #13171A;
  padding: 20px;
  border-radius: 16px;
  height: 80vh;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 1;
}

@media (max-width: 600px) {
  .modal-content > div{
    width: 100%;
  padding: 10px;

  }
  .modal-content {
    width: 100%;
    max-width: 95%;
  }
}

.modal-content::-webkit-scrollbar {
  width: 4px;
  /* Ширина скроллбара */
}

.modal-content::-webkit-scrollbar-track {
  background: #2e2e2e;
  /* Цвет фона трека скроллбара */
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #2e2e2e;
  /* Цвет ползунка скроллбара */
  border-radius: 10px;
  /* Закругленные углы */
  border: 3px solid #2e2e2e;
  /* Пространство вокруг ползунка */
}

.modal-content {
  scrollbar-width: thin;
  scrollbar-color: #7a7a7a #2e2e2e;
}

.modal-close {
  position: absolute;
  top: -12px;
  right: -8px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* Закругленные углы */
  background: #FB610A;
  z-index: 2133333333300;
  /* Более высокий z-index для отображения выше остальных элементов */
  /* overflow: auto; Чтобы кнопка была видна полностью */
}

.modal-close img {
  width: 12px;
  height: 12px;
}